<template>
  <div class="mini-list">
    <!-- 小程序列表 -->
    <div v-show="showMiniList">
      <div class="searchBox">
        <el-button type="primary" @click="openAddMiniDialog"
          >新增小程序</el-button
        >
      </div>
      <div class="tableBox">
        <el-table :data="tableDataMini" style="width: 100%" border>
          <el-table-column
            prop="name"
            label="企业名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="code"
            label="企业代码"
            align="center"
          ></el-table-column>
          <el-table-column prop="code_type" label="企业代码类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.code_type == 1">统一社会信用代码</span>
              <span v-else-if="scope.row.code_type == 2">组织机构代码</span>
              <span v-else-if="scope.row.code_type == 3">营业执照注册号</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="legal_persona_wechat"
            label="法人微信"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="legal_persona_name"
            label="法人姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="component_phone"
            label="第三方联系电话"
            align="center"
          ></el-table-column>
          <el-table-column prop="state" label="开通状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.state == 1">审核中</span>
              <span v-else-if="scope.row.state == 2">开通成功</span>
              <span v-else-if="scope.row.state == 3">开通失败</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="code_state"
            label="代码审核状态"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.code_state == 0">待审核</span>
              <span v-if="scope.row.code_state == 1">审核中</span>
              <span v-else-if="scope.row.code_state == 2">审核成功</span>
              <span v-else-if="scope.row.code_state == 3">审核失败</span>
              <span v-else-if="scope.row.code_state == 4">发布成功</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作" align="center">
            <template slot-scope="scope">
              <!-- <PrevReclick> -->
              <el-tag
                type="danger"
                size="medium"
                class="tag pointer"
                v-if="scope.row.state == 2 && scope.row.code_state >= 2"
                @click="releaseCode(scope.row)"
                >发布代码</el-tag
              >
              <!-- </PrevReclick> -->
              <el-tag
                type="danger"
                size="medium"
                class="tag pointer"
                v-if="scope.row.state == 2"
                @click="commitCode(scope.row)"
                >提交审核</el-tag
              >
              <el-tag
                type="danger"
                size="medium"
                class="tag pointer"
                v-if="scope.row.state == 2"
                @click="testCode(scope.row)"
                >获取体验版</el-tag
              >
              <el-tag
                type="danger"
                size="medium"
                class="tag pointer"
                v-if="scope.row.app_id"
                @click="journal(scope.row.app_id)"
                >日志</el-tag
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="mt10"
        background
        :page-count="totalPageMini"
        :current-page.sync="currentPageMini"
        @current-change="pageChangeMini"
      >
      </el-pagination>
      <!-- 新增小程序弹窗 -->
      <el-dialog
        title="新增小程序"
        :visible.sync="addMiniDialog"
        width="550px"
        center
        :show-close="false"
        :close-on-click-modal="false"
      >
        <el-form ref="form" label-width="130px">
          <el-form-item label="店铺id：">
            <el-input v-model="addMiniForm.shop_id"></el-input>
          </el-form-item>
          <el-form-item label="企业名：">
            <el-input v-model="addMiniForm.name"></el-input>
          </el-form-item>
          <el-form-item label="企业代码：">
            <el-input v-model="addMiniForm.code"></el-input>
          </el-form-item>
          <el-form-item label="企业代码类型：">
            <el-select
              v-model="addMiniForm.code_type"
              placeholder="请选择企业代码类型"
            >
              <el-option
                v-for="item in codeTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="法人微信：">
            <el-input v-model="addMiniForm.legal_persona_wechat"></el-input>
          </el-form-item>
          <el-form-item label="法人姓名：">
            <el-input v-model="addMiniForm.legal_persona_name"></el-input>
          </el-form-item>
          <el-form-item label="第三方联系电话：">
            <el-input v-model="addMiniForm.component_phone"></el-input>
          </el-form-item>
          <el-form-item>
            <!-- <PrevReclick> -->
            <el-button type="primary" @click="addMini">立即创建</el-button>
            <!-- </PrevReclick> -->
            <el-button class="cancelBtn" @click="addMiniDialog = false"
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 选择模板弹窗 -->
      <el-dialog
        title="选择模板"
        :visible.sync="templateDialog"
        width="1000px"
        :show-close="false"
        :close-on-click-modal="false"
        class="selectTemplate"
      >
        <div class="urgent flexbox flex-align-center" v-if="templateType == '1'">
          <span>是否加急：</span>
          <el-switch v-model="speedup_audit"></el-switch>
          <span>非特殊情况下不得使用</span>
        </div>
        <el-table
          :data="templateTableData"
          border
          style="width: 100%"
          @row-click="selectTemplate"
          :row-style="rowStyle"
        >
          <el-table-column
          prop="template_id"
          label="模板id"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="create_time"
          label="时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_version"
          label="版本号"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="user_desc"
          label="版本描述"
          align="center"
        ></el-table-column>
        </el-table>
        <el-pagination
          class="mt10"
          background
          :total="templateTotalPage"
          :page-size="5"
          :current-page.sync="templateCurrentPage"
          @current-change="templatePageChange"
        >
    </el-pagination>
        <span slot="footer" class="dialog-footer">
          <el-button @click="templateDialog = false">取 消</el-button>
          <el-button type="primary" @click="selectTemplateSure">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog
        title="提示"
        :visible.sync="commitCodeDialog"
        width="400px">
        <div>
          <p class="commit-code-tips">quota剩余值：{{commitCodeTips.rest}}</p>
          <p class="commit-code-tips">当月分配quota：{{commitCodeTips.limit}}</p>
          <p class="commit-code-tips">剩余加急次数：{{commitCodeTips.speedup_rest}}</p>
          <p class="commit-code-tips">当月分配加急次数：{{commitCodeTips.speedup_limit}}</p>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="commitCodeDialog = false">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 日志列表 -->
    <div v-show="!showMiniList">
      <div class="searchBox">
        <el-button type="primary" @click="showMiniList = true"
          >返回上一级</el-button
        >
      </div>
      <div class="tableBox">
        <el-table :data="tableDataJournal" style="width: 100%" border>
          <el-table-column
            prop="app_id"
            label="商户公众号APPID"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_version"
            label="模板版本号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_desc"
            label="模板描述"
            align="center"
          ></el-table-column>
          <el-table-column prop="type" label="类型" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 1">创建小程序</span>
              <span v-else-if="scope.row.type == 2">创建小程序成功</span>
              <span v-else-if="scope.row.type == 3">创建小程序失败</span>
              <span v-else-if="scope.row.type == 4">上传代码</span>
              <span v-else-if="scope.row.type == 5">代码提交审核</span>
              <span v-else-if="scope.row.type == 6">代码审核成功</span>
              <span v-else-if="scope.row.type == 7">代码审核失败</span>
              <span v-else-if="scope.row.type == 8">代码发布</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="remark"
            label="备注"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <el-pagination
        class="mt10"
        background
        :page-count="totalPageJournal"
        :current-page.sync="currentPageJournal"
        @current-change="pageChangeJournal"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  $sg_getMiniAppList,
  $sp_createWxApp,
  $sp_releaseCode,
  $sg_templateList,
  $sp_commitCode,
} from '@/api/miniapp';
import exportFileSync from '@/utils/file/exportFileSync';

export default {
  data() {
    return {
      showMiniList: true,
      addMiniForm: {
        shop_id: '',
        name: '', // 企业名
        code: '', // 企业代码
        code_type: '', // 企业代码类型（1：统一社会信用代码， 2：组织机构代码，3：营业执照注册号）
        legal_persona_wechat: '', // 法人微信
        legal_persona_name: '', // 法人姓名
        component_phone: '', // 第三方联系电话
      },
      codeTypeList: [
        {
          value: 1,
          label: '统一社会信用代码',
        },
        {
          value: 2,
          label: '组织机构代码',
        },
        {
          value: 3,
          label: '营业执照注册号',
        },
      ],
      addMiniDialog: false,
      totalPageMini: 1,
      currentPageMini: 1,
      tableDataMini: [],
      totalPageJournal: 1,
      currentPageJournal: 1,
      tableDataJournal: [],
      shop_id: '',
      templateDialog: false,
      templateTotalPage: 1,
      templateCurrentPage: 1,
      templateTableData: [],
      template_id: '',
      templateType: '',
      app_id: '',
      commitCodeDialog: false,
      commitCodeTips: {
        rest: '',
        limit: '',
        speedup_rest: '',
        speedup_limit: '',
      },
      speedup_audit: false, // 是否加急
    };
  },
  created() {
    this.getTableDataMini();
  },
  methods: {
    // 请求小程序列表数据
    getTableDataMini() {
      const params = {
        page: this.currentPageMini,
        pageSize: 20,
        method: 'app_list',
      };
      $sg_getMiniAppList(params).then((res) => {
        this.tableDataMini = res.data;
        this.totalPageMini = +res.page;
      });
    },
    // 打开新增小程序弹窗
    openAddMiniDialog() {
      Object.keys(this.addMiniForm).forEach((item) => {
        this.addMiniForm[item] = '';
      });
      this.addMiniDialog = true;
    },
    // 新增小程序
    async addMini() {
      await this.$validator(
        {
          ...this.addMiniForm,
        },
        [
          'shop_id/require/店铺id',
          'name/require/企业名',
          'code/require/企业代码',
          'code_type/require/企业代码类型',
          'legal_persona_wechat/require/法人微信',
          'legal_persona_name/require/法人姓名',
          'component_phone/require/第三方联系电话',
        ],
      );
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const params = {
        ...this.addMiniForm,
      };
      await $sp_createWxApp(params).then(() => {
        loading.close();
        this.addMiniDialog = false;
        this.getTableDataMini();
      }).catch(() => {
        loading.close();
      });
    },
    // 小程序列表翻页
    pageChangeMini() {
      this.getTableDataMini();
    },
    // 打开新增模板弹窗
    openTemplateDialog(templateType, app_id, shop_id) {
      this.speedup_audit = false;
      this.templateType = templateType;
      this.app_id = app_id;
      this.shop_id = shop_id;
      this.template_id = '';
      this.getTemplateList();
      this.templateDialog = true;
    },
    // 获取模板列表数据
    getTemplateList() {
      const params = {
        page: this.templateCurrentPage,
        pageSize: 5,
      };
      $sg_templateList(params).then((res) => {
        this.templateTableData = res.data;
        this.templateTotalPage = res.count;
      });
    },
    // 选择模板
    selectTemplate(row) {
      this.template_id = row.template_id;
    },
    // 更改选中行背景色
    rowStyle({ row }) {
      if (this.template_id === row.template_id) {
        return { 'background-color': '#F7EDED', cursor: 'pointer' };
      }
      return { cursor: 'pointer' };
    },
    // 确定选择模板
    selectTemplateSure() {
      if (!this.template_id) {
        this.$message.error('请选择模板');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      const params = {
        appid: this.app_id,
        template_id: this.template_id,
        path: this.shop_id <= 0 ? '/pages/spread/myMerchant/index' : '',
        // path: this.shop_id <= 0 ? '/pages/index/index' : '',
      };
      if (this.templateType === '1') {
        // 提交审核
        params.speedup_audit = this.speedup_audit ? 1 : 0;
        // ======上线时去掉=======================================================
        loading.close();
        this.templateDialog = false;
        if (process.env.VUE_APP_ENV !== 'production') {
          alert('开发环境不得使用提交审核功能');
          return;
        }
        // =======================================================================
        $sp_commitCode(params).then((res) => {
          loading.close();
          this.templateDialog = false;
          this.commitCodeTips.rest = res.rest;
          this.commitCodeTips.limit = res.limit;
          this.commitCodeTips.speedup_rest = res.speedup_rest;
          this.commitCodeTips.speedup_limit = res.speedup_limit;
          this.commitCodeDialog = true;
          this.getTableDataMini();
        }).catch(() => {
          loading.close();
        });
      } else if (this.templateType === '2') {
        // 获取体验版
        params.is_experience = 1;
        exportFileSync('/w/miniApp/commitCode', params, 'post', `qrcode_${this.app_id}_${this.template_id}.jpg`).then(() => {
          loading.close();
          this.templateDialog = false;
        }).catch(() => {
          loading.close();
        });
      }
    },
    // 模板列表翻页
    templatePageChange() {
      this.template_id = '';
      this.getTemplateList();
    },
    // 获取体验版
    testCode({ app_id, shop_id }) {
      this.openTemplateDialog('2', app_id, shop_id);
    },
    // 提交审核
    commitCode({ app_id, shop_id }) {
      this.openTemplateDialog('1', app_id, shop_id);
    },
    // 代码发布
    releaseCode({ shop_id, app_id }) {
      this.$confirm('确认要发布代码吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        showClose: false,
      })
        .then(() => {
          const loading = this.$loading({
            lock: true,
            text: '加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          const params = {
            shop_id,
            appid: app_id,
          };
          $sp_releaseCode(params).then(() => {
            loading.close();
            this.getTableDataMini();
          }).catch(() => {
            loading.close();
          });
        })
        .catch(() => {});
    },
    // 查看日志
    journal(app_id) {
      this.app_id = app_id;
      this.tableDataJournal = [];
      this.currentPageJournal = 1;
      this.totalPageJournal = 1;
      this.showMiniList = false;
      this.getJournalList();
    },
    // 请求日志列表
    getJournalList() {
      const params = {
        page: this.currentPageJournal,
        pageSize: 20,
        app_id: this.app_id,
        method: 'log_list',
      };
      $sg_getMiniAppList(params).then((res) => {
        this.tableDataJournal = res.data;
        this.totalPageJournal = +res.page;
      });
    },
    // 日志列表翻页
    pageChangeJournal() {
      this.getJournalList();
    },
  },
};
</script>
<style lang="scss">
.mini-list {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .tableBox {
    margin-top: 40px;
    .el-table {
      min-height: 580px;
    }
    .tag {
      margin-right: 10px;
    }
  }
  .cancelBtn {
    margin-left: 20px;
  }
  // 选择模板弹窗
  .selectTemplate {
    .urgent{
      width: 100%;
      margin-bottom: 20px;
      & > span:last-child{
        font-size: 12px;
        color: #999;
        margin-left: 10px;
      }
    }
    // 取消表格鼠标进入高亮显示
    .el-table__row:hover > td {
      background-color: transparent;
    }
  }
  .commit-code-tips{
    margin-top: 10px;
    font-size: 16px;
  }
}
</style>

